// / eslint-disable react-hooks/exhaustive - deps /
import React, { useState, useEffect } from 'react';
import Header from '../../Layouts/Header';
import Footer from '../../Layouts/Footer';
import Breadcrumb from '../../Layouts/Breadcrumb';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import swal from "sweetalert";
import AuthApi from "../../Services/Authapi";
import TextField from '@material-ui/core/TextField';
// import draftToHtml from 'draftjs-to-html';
// import { EditorState } from 'draft-js';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import AuthCheckComponent from '../../Layouts/Token';
import EmailEditor from 'react-email-editor';

const iniLatter = {
    template_name: '',
    template_subject: '',
    texteditor: '',
    template_design: '',
    templates: []
    // event_Id: 0,
}

const fontConfig = {
    fonts: {
        showDefaultFonts: true,
        customFonts: [
            { label: 'Arial', value: 'Arial, sans-serif' },
            { label: 'Georgia', value: 'Georgia, serif' },
            { label: 'Times New Roman', value: 'Times New Roman, serif' },
            { label: 'Verdana', value: 'Verdana, sans-serif' },
            { label: 'Courier New', value: '"Courier New", monospace' },
            { label: 'Tahoma', value: 'Tahoma, sans-serif' },
            { label: 'Trebuchet MS', value: '"Trebuchet MS", sans-serif' },
            { label: 'Lucida Sans', value: '"Lucida Sans", sans-serif' },
            { label: 'Roboto', value: 'Roboto, sans-serif' },
            { label: 'Open Sans', value: '"Open Sans", sans-serif' },
            { label: 'Lato', value: 'Lato, sans-serif' },
            { label: 'Montserrat', value: 'Montserrat, sans-serif' },
            { label: 'Oswald', value: 'Oswald, sans-serif' },
            { label: 'Raleway', value: 'Raleway, sans-serif' },
            { label: 'Merriweather', value: 'Merriweather, serif' },
            { label: 'Ubuntu', value: 'Ubuntu, sans-serif' },
            { label: 'Playfair Display', value: 'Playfair Display, serif' },
            { label: 'Poppins', value: 'Poppins, sans-serif' },
            { label: 'Quicksand', value: 'Quicksand, sans-serif' },
            { label: 'Roboto Slab', value: 'Roboto Slab, serif' },
            { label: 'Source Sans Pro', value: 'Source Sans Pro, sans-serif' },
            { label: 'Varela Round', value: 'Varela Round, sans-serif' },
            { label: 'Work Sans', value: 'Work Sans, sans-serif' },
            { label: 'Dancing Script', value: '"Dancing Script", cursive' },
            { label: 'Pacifico', value: '"Pacifico", cursive' },
            { label: 'Great Vibes', value: '"Great Vibes", cursive' },
            { label: 'Lobster', value: '"Lobster", cursive' },
            { label: 'Bebas Neue', value: '"Bebas Neue", cursive' },
            { label: 'Cinzel', value: 'Cinzel, serif' },
            { label: 'Abril Fatface', value: '"Abril Fatface", cursive' },
            { label: 'Amatic SC', value: '"Amatic SC", cursive' },
            { label: 'Josefin Sans', value: '"Josefin Sans", sans-serif' },
            { label: 'Playfair Display SC', value: '"Playfair Display SC", serif' },
            // Add more custom fonts here
        ],
    },
};

const EditEmailtemplate = (props) => {
    const classes = props;
    const [doc, setDoc] = useState(iniLatter);
    // const [editorState, setEditorState] = React.useState(
    //     EditorState.createEmpty());
    const [formErrors, setFormErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const emailEditorRef = React.useRef(null);

    useEffect(() => {
        if (props && props.authToken === false) {
            // props.history.push('/login');
        }
    }, []);

    useEffect(() => {
        getEventData();
    }, []);

    const getEventData = async () => {
        let Id = props.history.location.pathname.split('/')[props.history.location.pathname.split('/').length - 1];
        let upcomingEvent = await AuthApi.emailTemplategetbyid(Id);
        const event_Id = Id;
        let template_name = (upcomingEvent.status === true && upcomingEvent.data && upcomingEvent.data.template_name && (upcomingEvent.data.template_name !== null || upcomingEvent.data.template_name !== false)) ? upcomingEvent.data.template_name : null;
        let template_subject = (upcomingEvent.status === true && upcomingEvent.data && upcomingEvent.data.template_subject && (upcomingEvent.data.template_subject !== null || upcomingEvent.data.template_subject !== false)) ? upcomingEvent.data.template_subject : null;
        let template_body = (upcomingEvent.status === true && upcomingEvent.data && upcomingEvent.data.template_body && (upcomingEvent.data.template_body !== null || upcomingEvent.data.template_body !== false)) ? upcomingEvent.data.template_body : null;
        let template_design = (upcomingEvent.status === true && upcomingEvent.data && upcomingEvent.data.template_design) ? upcomingEvent.data.template_design : null;
        // let templates = (upcomingEvent.status === true && upcomingEvent.data && upcomingEvent.data.templates) ? upcomingEvent.data.templates : [];
        setDoc({
            template_name: template_name,
            template_subject: template_subject,
            texteditor: template_body,
            template_design: template_design,
            // templates: templates,
            event_Id: event_Id,
        });

        if (emailEditorRef.current && template_design) {
            const design = JSON.parse(template_design);
            // Ensure all elements are editable
            const updatedDesign = JSON.stringify(design, (key, value) => {
                if (key === 'selectable' || key === 'draggable' || key === 'duplicatable' || key === 'deletable' || key === 'hideable') {
                    return true;
                }
                return value;
            });
            emailEditorRef.current.editor.loadDesign(JSON.parse(updatedDesign));
        }
    };

    useEffect(() => { setTimeout(() => getEventData(), 500) }, []);

    const handleChange = (e, editorData) => {
        if (e && e.target) {
            const { name, value } = e.target;
            setDoc({ ...doc, [name]: value });
        } else if (editorData !== undefined) {
            setDoc({ ...doc, texteditor: editorData });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormErrors(validate(doc));
        setIsSubmitting(true);
        // console.log("Submitting document:", doc); // Debugging: Check the state before submission
    };

    const validate = (values) => {
        let errors = {};
        const regex = /^[a-zA-Z]/;

        if (!values.template_name) {
            errors.template_name = "Cannot be blank";
        } else if (!regex.test(values.template_name)) {
            errors.template_name = "Invalid title format";
        }
        return errors;
    };

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmitting) {
            editUser1();
        }
    }, [formErrors]);

    const editUser1 = async () => {
        swal({
            title: "Loading",
            text: "Please wait...",
            buttons: false,
            closeOnClickOutside: false,
            closeOnEsc: false,
            width: "10%",
            content: {
                element: "img",
                attributes: {
                    src: "https://www.boasnotas.com/img/loading2.gif",
                    style: "width: 50px;"
                }
            }
        });
        try {
            emailEditorRef.current.editor.exportHtml(async (data) => {
                const { design, html } = data;
                const updatedDoc = { ...doc, texteditor: html, template_design: JSON.stringify(design) };

                let status = await AuthApi.emailTemplateupdate(updatedDoc, doc.event_Id);
                swal.close();
                if (status && status.status === true) {
                    await getEventData();
                    props.history.push("/emailtemplates");
                }
            });
        } catch (error) {
            console.error("Error updating template:", error);
        }
    };

    // const addTemplateField = () => {
    //     setDoc({ ...doc, templates: [...doc.templates, { label: '', type: '', options: [] }] });
    // };

    // const handleTemplateChange = (index, field, value) => {
    //     const newTemplates = [...doc.templates];
    //     newTemplates[index][field] = value;
    //     setDoc({ ...doc, templates: newTemplates });
    // };

    // const handleOptionChange = (templateIndex, optionIndex, value) => {
    //     const newTemplates = [...doc.templates];
    //     newTemplates[templateIndex].options[optionIndex] = value;
    //     setDoc({ ...doc, templates: newTemplates });
    // };

    // const addOption = (index) => {
    //     const newTemplates = [...doc.templates];
    //     if (!newTemplates[index].options) {
    //         newTemplates[index].options = [];
    //     }
    //     newTemplates[index].options.push('');
    //     setDoc({ ...doc, templates: newTemplates });
    // };

    // const removeOption = (templateIndex, optionIndex) => {
    //     const newTemplates = [...doc.templates];
    //     newTemplates[templateIndex].options.splice(optionIndex, 1);
    //     setDoc({ ...doc, templates: newTemplates });
    // };

    // const removeTemplateField = (index) => {
    //     const newTemplates = doc.templates.filter((_, i) => i !== index);
    //     setDoc({ ...doc, templates: newTemplates });
    // };

    return (
        <div>
            <AuthCheckComponent authToken={props.authToken} />
            <Header
                {...props}
                authUser={props.authUser}
                component={
                    <div>
                        <Card className={classes.root} style={{ marginBottom: '3%' }}>
                            <CardContent>
                                <Breadcrumb
                                    {...props}
                                    primaryPageName="Email Templates"
                                    primaryPageLink="/emailtemplates"
                                    isSecondaryPage={false}
                                    secondaryPageName="" />
                            </CardContent>
                        </Card>
                        <Grid container className={classes.root} spacing={2}>
                            <Grid item xs={12}>
                                <Card className={classes.root}>
                                    <CardContent>
                                        <Typography variant="h2" style={{ paddingTop: 0, paddingLeft: 0, paddingBottom: 0 }}>Edit Email Template</Typography>
                                        <form className={classes.form} onSubmit={handleSubmit}>
                                            <Grid container className={classes.root} spacing={5}>
                                                <Grid item xs={12}>
                                                    {/* {console.log(doc.title)} */}
                                                    <TextField
                                                        fullWidth
                                                        id="outlined-basic"
                                                        name="template_name"
                                                        label="Template Name"
                                                        variant="outlined"
                                                        error={formErrors.template_name && true}
                                                        value={doc.template_name}
                                                        onChange={handleChange}
                                                        className={formErrors.template_name ? "input-error" : ""}
                                                    />
                                                    {formErrors.template_name && (
                                                        <span className="error">
                                                            {formErrors.template_name}
                                                        </span>
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container className={classes.root} spacing={5}>
                                                <Grid item xs={12}>
                                                    {/* {console.log(doc.title)} */}
                                                    <TextField
                                                        fullWidth
                                                        id="outlined-basic"
                                                        name="template_subject"
                                                        label="Template Subject"
                                                        variant="outlined"
                                                        error={formErrors.template_subject && true}
                                                        value={doc.template_subject}
                                                        onChange={handleChange}
                                                        className={formErrors.template_subject ? "input-error" : ""}
                                                    />
                                                    {formErrors.template_subject && (
                                                        <span className="error">
                                                            {formErrors.template_subject}
                                                        </span>
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container className={classes.root} spacing={5}>
                                                <Grid item xs={12}>
                                                    <EmailEditor
                                                        ref={emailEditorRef}
                                                        onLoad={() => {
                                                            if (doc.template_design) {
                                                                const design = JSON.parse(doc.template_design);
                                                                // Ensure all elements are editable
                                                                const updatedDesign = JSON.stringify(design, (key, value) => {
                                                                    if (key === 'selectable' || key === 'draggable' || key === 'duplicatable' || key === 'deletable' || key === 'hideable') {
                                                                        return true;
                                                                    }
                                                                    return value;
                                                                });
                                                                emailEditorRef.current.editor.loadDesign(JSON.parse(updatedDesign));
                                                            }
                                                        }}
                                                        options={fontConfig}
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid container className={classes.root} spacing={3}>
                                                <Grid item xs={4}>
                                                    <Button type="submit" style={{ marginTop: "10px", float: 'left', marginBottom: "15px" }} variant="contained" color="primary">
                                                        Edit
                                                    </Button>
                                                    <Button type="button" style={{ marginTop: "10px", float: 'left', marginBottom: "15px" }} onClick={(e) => { e.preventDefault(); props.history.push('/emailtemplates') }}>
                                                        Cancel
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </div>
                } />
            <Footer />
        </div>
    );
};

export default EditEmailtemplate;    