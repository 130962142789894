import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
// import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { IoArrowRedoOutline } from "react-icons/io5";
// import Tooltip from "@material-ui/core/Tooltip";
import AuthApi from "../Services/Authapi";
import ls from "local-storage";
import Logo from "../../Images/logo.png";
import FontAwesomeIconComponent from "./FontAwesomeIconComponent";
import Nav from "./Nav";
import Tooltip from "@material-ui/core/Tooltip";
// import { revokeAccess } from "../../ScreenShot";
// import Button from "@material-ui/core/Button";
// import Fade from "@material-ui/core/Fade";
// import Grow from "@material-ui/core/Grow";
// import Paper from "@material-ui/core/Paper";
// import Popper from "@material-ui/core/Popper";
// import ClickAwayListener from "@material-ui/core/ClickAwayListener";
// import MenuList from "@material-ui/core/MenuList";
import { Collapse } from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { useHistory } from 'react-router-dom';
// import StarBorder from "@material-ui/icons/StarBorder";
// import { useState } from "react";
// import Badge from "@mui/material/Badge";
// import MailIcon from "@mui/icons-material/Mail";
// import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import Notification from "./Notification";
import swal from 'sweetalert';
import "./header.css"
// import ls from "local-storage";
import Switch from "react-switch";
// import Timer from "../Pages/TImer/timer";

const drawerWidth = 240;

function notificationsLabel(count) {
  if (count === 0) {
    return "no notifications";
  }
  if (count > 99) {
    return "more than 99 notifications";
  }
  return `${count} notifications`;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  ds: {
    position: "absolute",
    right: 0,
  },
  back: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  activeMenuItem: {
    backgroundColor: " #e3e6f1",
  },
}));

export default function MiniDrawer(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const opens = Boolean(anchorEl);
  const [ds, setDs] = React.useState(false);
  const [ds1, setDs1] = React.useState(false);
  const [docu, setDocu] = React.useState(false);
  const [user, setuser] = React.useState(false);
  const [userimg1, setuserimg] = React.useState([]);
  const history = useHistory();

  const handleClick1 = () => {
    setDs1(!ds1);
  };


  const handleClick = () => {
    setDs(!ds);
  };
  const usersClick = () => {
    setuser(!user);
  };


  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     const sessionItem = sessionStorage.getItem('mySessionItem');
  //     // console.log(sessionItem)
  //     if (sessionItem === "someValue") {
  //       alert("karmur hardik");
  //     } else {
  //       AuthApi.outTime();
  //       // this.props.history.push("/login");
  //       alert("kkkk");

  //     }
  //   }, 10000);

  //   return () => clearInterval(intervalId); 
  // }, []);

  const DocumentClick = () => {
    setDocu(!docu);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    userimg();
  }, []);

  const userimg = async (e) => {
    let users = await AuthApi.userimg();
    setuserimg(users);
  };

  const logOut = async (e) => {
    try {
      outTimer();
      setTimeout(async () => {
        let logOutResponse = await AuthApi.logout();
        if (logOutResponse && logOutResponse.status === true) {
          ls.set("authToken", false);
          ls.set("isActive", false);
          props.setAutUser({ authToken: false, authUser: false });
          ls.clear();
          history.push("/login");
        } else {
          console.error("Logout failed: ", logOutResponse);
        }
      }, 1000);
    } catch (error) {
      console.error("An error occurred during logout: ", error);
    }
  };



  const outTimer = async (e) => {
    await AuthApi.outTime();
  };

  const hhhh = window.location.pathname;
  const permissions = (props.authUser && Array.isArray(props.authUser.userAccess)) ? props.authUser.userAccess : [];


  const toggleRole = async () => {
    try {
      const response = await AuthApi.toggleRole(ls("user").id);
      if (response && response.status === true) {
        getAuth();
        handleRefresh1()
      }
    } catch (error) {
      alert('An error occurred');
    }
  };

  const is_role_toggled = ls("user").is_role_toggled;
  const role = ls("roles")

  const getAuth = async () => {
    swal({
      title: "Loading...",
      text: "Please wait while we changing your interface.",
      icon: "info",
      buttons: false,
      closeOnClickOutside: false,
      closeOnEsc: false,
    });

    let checkLogin = await AuthApi.checkAuth();
    if (checkLogin && checkLogin.status !== false) {
      ls.set("user", checkLogin.data.user || false);
      window.location.reload();
    } else {
      ls.set("roles", false);
      ls.set("authToken", false);
      ls.set("user", false);
      props.setAutUser({
        authUser: false,
        authToken: false,
        roles: false,
        user: false,
      });
      swal.close();
    }
  };



  const handleRefresh1 = async () => {
    try {
      let formData = {
        user_id: ls("user").id
      };
      const newToken = await AuthApi.refreshToken(formData);
      localStorage.setItem('authToken', newToken.access_token);
      localStorage.setItem('roles', newToken.roles);
      localStorage.setItem('tokenRefreshed', 'true');

      // window.location.reload();
    } catch (error) {
      console.error('Error refreshing token:', error);
    }
  };


  const doj = new Date(ls("user").doj);
  const currentDate = new Date();
  const monthsDifference = (currentDate.getFullYear() - doj.getFullYear()) * 12 + currentDate.getMonth() - doj.getMonth();
  const reviewFormStatus = ls("user").Review_form_status;


  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            className={classes.ds}
            color="inherit"
          >
            <Tooltip title="Profile">
              <img src={userimg1.data} alt="User" style={{ width: "30px", height: "30px", borderRadius: "50%" }} />
            </Tooltip>
          </IconButton>





          {is_role_toggled === 1 || role === "Super Admin" ? (
            <Tooltip title={is_role_toggled === 1 ? "Switch to Super Admin " : "Switch to Employee"}>
              <label
                style={{
                  position: "absolute",
                  right: "102px"
                }}
              >
                <Switch onChange={toggleRole} checked={is_role_toggled} />
              </label>
            </Tooltip>
          ) : null}




          <Notification
            {...props}
          />



          <Menu
            style={{
              top: "48px",
            }}
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={opens}
            onClose={handleClose}
          >
            <div
              style={{
                width: "200px",
              }}
            >
              {/* // ... existing code ... */}
              <MenuItem
                onClick={(e) => {
                  e.preventDefault();
                  const userId = ls("user").id;
                  console.log(userId)
                  props.history.push(`/users/${userId}`);
                }}
                primary="Profile"
                title="Profile"
                placement="right"
              >
                {/* // ... existing code ... */}
                <FontAwesomeIconComponent
                  classes="fas fa-user-circle"
                  colorName="primary"
                  fontSize={"small"}
                />
                <span style={{ marginLeft: "15px" }}>Edit Profile</span>
              </MenuItem>
              <MenuItem
                onClick={(e) => {
                  logOut(e);
                }}
              >
                <FontAwesomeIconComponent
                  classes="fas fa-sign-out-alt"
                  colorName="primary"
                  fontSize={"small"}
                />
                <span style={{ marginLeft: "15px" }}> Sign Out</span>
              </MenuItem>
            </div>
          </Menu>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <img
            style={{ height: "35px", paddingRight: "30px" }}
            src={Logo}
            alt="ds"
          />
          <h1 style={{ fontSize: "20px", paddingRight: "20px" }}>CPSI EMS</h1>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          <div className={hhhh === "/dashboard" ? "slideractive" : ""}>
            {permissions.includes("Dashboard.Timeshow") && (
              <Nav
                key="dashboard"
                onClick={(e) => {
                  e.preventDefault();
                  props.history.push("/dashboard");
                }}
                classes="fa fa-home"
                primary="Dashboard"
                title="Dashboard"
                placement="right"
              // className="slideractive"
              />
            )}
          </div>
          <div className={hhhh === "/Attendance" ? "slideractive" : ""}>
            {permissions.includes("Attendance.Show") && (
              <Nav
                key={Math.random()}
                onClick={(e) => {
                  e.preventDefault();
                  props.history.push("/Attendance");
                }}
                classes="far fa-calendar-alt"
                primary="Attendance"
                title="Attendance"
                placement="right"
              // className={hhhh === "/dashboard" ? "slideractive" : ""}
              />
            )}
          </div>
          <div>
            {(permissions.includes("Permission.Show") || permissions.includes("Role.Show") || permissions.includes("Users.Show")) && (
              <div>
                <ListItem button onClick={usersClick} className={hhhh === "/permission" || hhhh === "/role" || hhhh === "/users" ? classes.activeMenuItem : ""}>
                  <Tooltip title="Users">
                    <ListItemIcon>
                      <FontAwesomeIconComponent
                        classes="far fa-file-alt"
                        colorName="primary"
                        fontSize={"small"}
                      />
                      {!open && (user ? <ExpandLess /> : <ExpandMore />)}
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText primary="Users" />
                  {open && (user ? <ExpandLess /> : <ExpandMore />)}
                </ListItem>

                <Collapse in={user} timeout="auto" unmountOnExit>
                  <List disablePadding>
                    {permissions.includes("Permission.Show") && (
                      <Tooltip title="Permission">
                        <ListItem
                          button
                          onClick={(e) => {
                            e.preventDefault();
                            props.history.push("/permission");
                          }}
                          className={clsx(classes.nested, hhhh === "/permission" && classes.activeMenuItem)}
                          style={{ paddingLeft: "29px" }}
                        >
                          <i className="fa fa-lock"
                            fontSize={"medium"}
                            style={{ width: "28px", color: "#1b6e95" }}
                          >
                          </i>
                          <ListItemText style={{ marginLeft: "29px" }} primary="Manage Permission" />
                        </ListItem>
                      </Tooltip>
                    )}

                    {permissions.includes("Role.Show") && (
                      <Tooltip title="Role">
                        <ListItem
                          button
                          onClick={(e) => {
                            e.preventDefault();
                            props.history.push("/role");
                          }}
                          className={clsx(classes.nested, hhhh === "/role" && classes.activeMenuItem)}
                          style={{ paddingLeft: "29px" }}
                        >
                          <i className="fas fa-user-cog"
                            fontSize={"medium"}
                            style={{ width: "28px", color: "#1b6e95" }}
                          >
                          </i>
                          <ListItemText style={{ marginLeft: "29px" }} primary="Manage Role" />
                        </ListItem>
                      </Tooltip>
                    )}

                    {permissions.includes("Users.Show") && (
                      <Tooltip title="Users">
                        <ListItem
                          button
                          onClick={(e) => {
                            e.preventDefault();
                            props.history.push("/users");
                          }}
                          className={clsx(classes.nested, hhhh === "/users" && classes.activeMenuItem)}
                          style={{ paddingLeft: "29px" }}
                        >
                          <i className="fas fa-users"
                            fontSize={"medium"}
                            style={{ width: "28px", color: "#1b6e95" }}
                          >
                          </i>
                          <ListItemText style={{ marginLeft: "29px" }} primary="Manage Users" />
                        </ListItem>
                      </Tooltip>
                    )}
                  </List>
                </Collapse>
              </div>
            )}
          </div>

          <div className={hhhh === "/leaves" ? "slideractive" : ""}>
            {permissions.includes("Holidays.Show") && (
              <Nav
                key={Math.random()}
                onClick={(e) => {
                  e.preventDefault();
                  props.history.push("/leaves");
                }}
                classes="fas fa-sign-out-alt"
                primary="Holidays"
                title="Leaves & Holidays"
                placement="right"
                className={hhhh === "/leaves" ? classes.activeMenuItem : ""}
              />
            )}
          </div>

          <div className={hhhh === "/upcoming" ? "slideractive" : ""}>
            {permissions.includes("Upcomingevent.Show") && (
              <Nav
                key={Math.random()}
                onClick={(e) => {
                  e.preventDefault();
                  props.history.push("/upcoming");
                }}
                classes="fa fa-arrow-right"
                primary="Upcoming Event"
                title="Upcoming Event"
                placement="right"
                className={hhhh === "/upcoming" ? classes.activeMenuItem : ""}
              />
            )}
          </div>

          <div className={hhhh === "/tickets" ? "slideractive" : ""}>
            {permissions.includes("Leaveticket.Show") && (
              <Nav
                key={Math.random()}
                onClick={(e) => {
                  e.preventDefault();
                  props.history.push("/tickets");
                }}
                classes="fas fa-ticket-alt"
                primary="Leaves Tickets"
                title="Leave Tickets"
                placement="right"
                className={hhhh === "/tickets" ? classes.activeMenuItem : ""}
              />
            )}
          </div>

          {(permissions.includes("Job.Show") ||
            permissions.includes("Qualification.Show") ||
            permissions.includes("Experience.Show") ||
            permissions.includes("Department.Show") ||
            permissions.includes("designation.Show")
          ) && (
              <ListItem button onClick={handleClick} className={hhhh === "/job" || hhhh === "/qulification" || hhhh === "/experience" ? classes.activeMenuItem : ""}>
                <Tooltip title="Job-Portal">
                  <ListItemIcon>
                    <FontAwesomeIconComponent
                      classes="fas fa-briefcase"
                      colorName="primary"
                      fontSize={"small"}
                    />
                    {!open && (ds ? <ExpandLess /> : <ExpandMore />)}
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Job-Portal" />
                {open && (ds ? <ExpandLess /> : <ExpandMore />)}
              </ListItem>
            )}

          <Collapse in={ds} timeout="auto" unmountOnExit>
            <List disablePadding>
              {permissions.includes("Job.Show") && (
                <Tooltip title="Job">
                  <ListItem
                    button
                    onClick={(e) => {
                      e.preventDefault();
                      props.history.push("/job");
                    }}
                    className={clsx(classes.nested, hhhh === "/job" && classes.activeMenuItem)}
                    style={{ paddingLeft: "29px" }}
                  >
                    <i className="fa fa-suitcase"
                      fontSize={"medium"}
                      style={{ width: "28px", color: "#1b6e95" }}
                    >
                    </i>
                    <ListItemText style={{ marginLeft: "29px" }} primary="Job" />
                  </ListItem>
                </Tooltip>
              )}

              {permissions.includes("Qualification.Show") && (
                <Tooltip title="Qualification">
                  <ListItem
                    button
                    onClick={(e) => {
                      e.preventDefault();
                      props.history.push("/qulification");
                    }}
                    className={clsx(classes.nested, hhhh === "/qulification" && classes.activeMenuItem)}
                    style={{ paddingLeft: "29px" }}
                  >
                    <i className="fa fa-graduation-cap"
                      fontSize={"medium"}
                      style={{ width: "28px", color: "#1b6e95" }}
                    >
                    </i>
                    <ListItemText style={{ marginLeft: "29px" }} primary="Qualification" />
                  </ListItem>
                </Tooltip>
              )}

              {permissions.includes("Experience.Show") && (
                <Tooltip title="Experience">
                  <ListItem
                    button
                    onClick={(e) => {
                      e.preventDefault();
                      props.history.push("/experience");
                    }}
                    className={clsx(classes.nested, hhhh === "/experience" && classes.activeMenuItem)}
                    style={{ paddingLeft: "29px" }}
                  >
                    <i className="fa fa-business-time"
                      fontSize={"medium"}
                      style={{ width: "28px", color: "#1b6e95" }}
                    >
                    </i>
                    <ListItemText style={{ marginLeft: "29px" }} primary="Experience" />
                  </ListItem>
                </Tooltip>
              )}

              {permissions.includes("designation.Show") && (
                <Tooltip title="All Designation">
                  <ListItem
                    button
                    onClick={(e) => {
                      e.preventDefault();
                      props.history.push("/designation");
                    }}
                    className={clsx(classes.nested, hhhh === "/designation" && classes.activeMenuItem)}
                    style={{ paddingLeft: "29px" }}
                  >
                    <i className="fas fa-user-tie"
                      fontSize={"medium"}
                      style={{ width: "28px", color: "#1b6e95" }}
                    >
                    </i>
                    <ListItemText style={{ marginLeft: "29px" }} primary="All Designation" />
                  </ListItem>
                </Tooltip>
              )}


              {permissions.includes("Department.Show") && (
                <Tooltip title="All Department">
                  <ListItem
                    button
                    onClick={(e) => {
                      e.preventDefault();
                      props.history.push("/Departmente");
                    }}
                    className={clsx(classes.nested, hhhh === "/Departmente" && classes.activeMenuItem)}
                    style={{ paddingLeft: "29px" }}
                  >
                    <i className="fas fa-building"
                      fontSize={"medium"}
                      style={{ width: "28px", color: "#1b6e95" }}
                    >
                    </i>
                    <ListItemText style={{ marginLeft: "29px" }} primary="All Department" />
                  </ListItem>
                </Tooltip>
              )}
            </List>
          </Collapse>

          {/* <div className={hhhh === "/time" ? "slideractive" : ""}>
            {permissions.includes("Time.Add") && (
              <Nav
                key={Math.random()}
                onClick={(e) => {
                  e.preventDefault();
                  props.history.push("/time");
                }}
                classes="far fa-times-circle"
                primary="Time Entry"
                title="Time Entry"
                placement="right"
                className={hhhh === "/time" ? classes.activeMenuItem : ""}
              />
            )}
          </div> */}


          <div className={hhhh === "/check" ? "slideractive" : ""}>
            {permissions.includes("Timeentry.Show") && (
              <Nav
                key={Math.random()}
                onClick={(e) => {
                  e.preventDefault();
                  props.history.push("/check");
                }}
                classes="fas fa-clipboard-check"
                primary="User Check"
                title="User Check"
                placement="right"
                className={hhhh === "/check" ? classes.activeMenuItem : ""}
              />
            )}
          </div>

          <div className={hhhh === "/emailtemplates" ? "slideractive" : ""}>
          {permissions.includes("Emailtemplates.Show") && (
              <Nav
                key={Math.random()}
                onClick={(e) => {
                  e.preventDefault();
                  props.history.push("/emailtemplates");
                }}
                classes="fa fa-envelope-square"
                primary="Email Templates"
                title="Email Templates"
                placement="right"
                className={hhhh === "/emailtemplates" ? classes.activeMenuItem : ""}
              />
         )}
          </div>

          {(permissions.includes("Documentslist.Show") || permissions.includes("Template.Show")) && (
            <ListItem button onClick={DocumentClick} className={hhhh === "/Documentslist" || hhhh === "/latter" ? classes.activeMenuItem : ""}>
              <Tooltip title="Documents">
                <ListItemIcon>
                  <FontAwesomeIconComponent
                    classes="far fa-file-alt"
                    colorName="primary"
                    fontSize={"small"}
                  />
                  {!open && (docu ? <ExpandLess /> : <ExpandMore />)}
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Documents" />
              {open && (docu ? <ExpandLess /> : <ExpandMore />)}
            </ListItem>
          )}

          {(permissions.includes("Documentslist.Show") || permissions.includes("Template.Show")) && (
            <Collapse in={docu} timeout="auto" unmountOnExit>
              <List disablePadding>

                {permissions.includes("Template.Show") && (
                  <Tooltip title="Template">
                    <ListItem
                      button
                      onClick={(e) => {
                        e.preventDefault();
                        props.history.push("/latter");
                      }}
                      className={clsx(classes.nested, hhhh === "/latter" && classes.activeMenuItem)}
                      style={{ paddingLeft: "29px" }}
                    >
                      <i className="fa fa-file-pdf"
                        fontSize={"small"}
                        style={{ width: "28px", color: "#1b6e95" }}
                      >
                      </i>
                      <ListItemText style={{ marginLeft: "29px" }} primary="Template" />
                    </ListItem>
                  </Tooltip>
                )}


                {permissions.includes("Documentslist.Show") && (
                  <Tooltip title="Documentslist">
                    <ListItem
                      button
                      onClick={(e) => {
                        e.preventDefault();
                        props.history.push("/Documentslist");
                      }}
                      className={clsx(classes.nested, hhhh === "/Documentslist" && classes.activeMenuItem)}
                      style={{ paddingLeft: "29px" }}
                    >
                      <i className="fa fa-file"
                        fontSize={"small"}
                        style={{ width: "28px", color: "#1b6e95" }}
                      >
                      </i>
                      <ListItemText style={{ marginLeft: "29px" }} primary="User Documentslist" />
                    </ListItem>
                  </Tooltip>
                )}
              </List>
            </Collapse>
          )}

          <div className={hhhh === "/Cron" ? "slideractive" : ""}>
            {permissions.includes("Cron.Show") && (
              <Nav
                key={Math.random()}
                onClick={(e) => {
                  e.preventDefault();
                  props.history.push("/Cron");
                }}
                classes="fas fa-clock"
                primary="Cron"
                title="Cron"
                placement="right"
                className={hhhh === "/Cron" ? classes.activeMenuItem : ""}
              />
            )}
          </div>

          {/* {console.log(ls("user").doj)} */}
          {((permissions.includes("Form360.Add") && monthsDifference >= 11) ||
  permissions.includes("Reviewformadd.Show") ||
  (permissions.includes("Form360.Show") && ls("roles") === "Employee" && monthsDifference >= 11) ||
  reviewFormStatus === 1 // Check if Review_form_status is 1
) && (
  <ListItem button onClick={handleClick1} className={hhhh === "/Form360" || hhhh === "/Reviewformlist" ? classes.activeMenuItem : ""}>
    <Tooltip title="Feedback">
      <ListItemIcon>
        <FontAwesomeIconComponent
          classes="fas fa-file-alt"
          colorName="primary"
          fontSize={"small"}
        />
        {!open && (ds1 ? <ExpandLess /> : <ExpandMore />)}
      </ListItemIcon>
    </Tooltip>
    <ListItemText primary="Feedback" />
    {open && (ds1 ? <ExpandLess /> : <ExpandMore />)}
  </ListItem>
)}

          <Collapse in={ds1} timeout="auto" unmountOnExit>
            <List disablePadding>
              {permissions.includes("Form360.Add") && (
                <Tooltip title="Form360">
                  <ListItem
                    button
                    onClick={(e) => {
                      e.preventDefault();
                      props.history.push("/Form360");
                    }}
                    className={clsx(classes.nested, hhhh === "/Form360" && classes.activeMenuItem)}
                    style={{ paddingLeft: "29px" }}
                  >
                    <i className="	fas fa-list-alt"
                      fontSize={"medium"}
                      style={{ width: "28px", color: "#1b6e95" }}
                    >
                    </i>
                    <ListItemText style={{ marginLeft: "29px" }} primary="Form360" />
                  </ListItem>
                </Tooltip>
              )}


              {permissions.includes("Reviewformadd.Show") && (
                <Tooltip title="Reviewformlist">
                  <ListItem
                    button
                    onClick={(e) => {
                      e.preventDefault();
                      props.history.push("/Reviewformlist");
                    }}
                    className={clsx(classes.nested, hhhh === "/Reviewformlist" && classes.activeMenuItem)}
                    style={{ paddingLeft: "29px" }}
                  >
                    <i className="fas fa-list-ul"
                      fontSize={"medium"}
                      style={{ width: "28px", color: "#1b6e95" }}
                    >
                    </i>
                    <ListItemText style={{ marginLeft: "29px" }} primary="Reviewformlist" />
                  </ListItem>
                </Tooltip>
              )}

            </List>
          </Collapse>

          {/* <div className={hhhh === "/upcoming" ? "slideractive" : ""}> */}
          {permissions.includes("Settings.Show") && (
            <Nav
              key={Math.random()}
              onClick={(e) => {
                e.preventDefault();
                props.history.push("/Settings");
              }}
              classes="fa fa-cog"
              primary="Settings"
              title="Settings"
              placement="right"
            // className={hhhh === "/upcoming" ? classes.activeMenuItem : ""}
            />
          )}
          {/* </div> */}


          {/* <div className={hhhh === "/Form360" ? "slideractive" : ""}>
            {permissions.includes("Form360.Add") && (
              <Nav
                key={Math.random()}
                onClick={(e) => {
                  e.preventDefault();
                  props.history.push("/Form360");
                }}
                classes="fas fa-clock"
                primary="Form360"
                title="Form360"
                placement="right"
                className={hhhh === "/Form360" ? classes.activeMenuItem : ""}
              />
            )}
          </div> */}












        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.component}
      </main>
    </div>
  );
}
