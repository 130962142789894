import React, { Component } from "react";
import Header from "../../Layouts/Header";
import Footer from "../../Layouts/Footer";
import AuthApi from "../../Services/Authapi";
import TableComponent from "../../Layouts/TableComponent";
import Breadcrumb from "../../Layouts/Breadcrumb";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import swal from "sweetalert";
import ls from "local-storage";
import IconButton from "@material-ui/core/IconButton";
import AuthCheckComponent from "../../Layouts/Token";
import Select from "@material-ui/core/Select";
import { withRouter } from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Grid from "@material-ui/core/Grid";
import Paper from '@material-ui/core/Paper';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
} from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
// import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// import CancelIcon from "@mui/icons-material/Cancel";
import Tooltip from "@material-ui/core/Tooltip";
import FontAwesomeIconComponent from "../../Layouts/FontAwesomeIconComponent";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import Checkbox from "@material-ui/core/Checkbox";
import "./Ticket.css"
import CircularProgress from "@material-ui/core/CircularProgress";
import ReactDOM from 'react-dom';
import moment from 'moment';
// import { DatePicker } from "@mui/x-date-pickers";

class Ticket extends Component {
  constructor(props) {
    const userRole = ls("roles");
    const isEmployeeRole = userRole && userRole === "Employee";
    const isAdmin = userRole && userRole === "Super Admin";
    super(props);
    this.state = {
      tickets: [],
      count: 0,
      searchQuery: "",
      suggestions: [],
      showApprovedDetailsPopup: false,



      
      showRejectedReason: false,
      rejectedReason: "",
      approvedTickets: {},
      selectedTickets: [],
      selectedBulkAction: "",
      selectedLeaveDetails: null,
      showModalFromNotification: false,
      filterStatus: ["All"],
      allSelected: false,
      tableTh: [
        {
          id: "select",
          numeric: false,
          disablePadding: true,
          label: (
            <div className="wrap-check-20">
              <label>
                <input
                  type="checkbox"
                  checked={this.isAllSelected()}
                  onChange={this.handleSelectAllTickets}
                />
                <span className="checkbox"></span>
              </label>
            </div>
          ),
          sortable: false,
        },
        {
          id: "firstName",
          numeric: false,
          disablePadding: true,
          label: "Name",
          sortable: true,
        },
        {
          id: "leaveType",
          numeric: false,
          disablePadding: true,
          label: " Leave Type",
          sortable: true,
        },
        {
          id: "date_range",
          numeric: false,
          disablePadding: true,
          label: "Date Range",
          sortable: true,
        },
        {
          id: "no_of_days",
          numeric: false,
          disablePadding: true,
          label: "No Of Days",
          sortable: true,
        },
        {
          id: "ticket_mesasge",
          numeric: false,
          disablePadding: true,
          label: "Reason For Leave",
          sortable: false,
          style: { width: "100px" },
        },
        {
          id: "status",
          numeric: false,
          disablePadding: true,
          label: "Status",
          sortable: false,
        },
        {
          id: "reason",
          numeric: false,
          disablePadding: true,
          label: "Reason for Declined",
          sortable: false,
          style: { width: "100px" },
        },
        {
          id: "c_reason",
          numeric: false,
          disablePadding: true,
          label: "Reason for Cancelled",
          sortable: false,
          style: { width: "100px" },
        },
        {
          id: "created_at",
          numeric: false,
          disablePadding: true,
          label: "Created On",
          sortable: true,
        },
        {
          id: "updated_at",
          numeric: false,
          disablePadding: true,
          label: "Updated",
          sortable: true,
        },
        {
          id: "action",
          numeric: false,
          disablePadding: true,
          label: "Action",
          sortable: false,
          style: { width: "100px" },
        },
        ...(isEmployeeRole
          ? ""
          : [
            {
              numeric: false,
              disablePadding: true,
              sortable: false,
              style: { width: "100px" },
            },
          ]),
        ...(isAdmin
          ? ""
          : [
            {
              id: "edit",
              numeric: false,
              disablePadding: true,
              sortable: true,
            },
          ]),
      ],
      loading: false,
      rejectedTickets: {},
      selectedMonth: "",
      filteredLeave: [],
      startDate: null,
      endDate: null,
      expandedMessages: {},
      isReasonExpanded: false,
    };

    this.ticketsData = this.ticketsData.bind(this);
    this.updateLeaveStatus = this.updateLeaveStatus.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.handleSelectTicket = this.handleSelectTicket.bind(this);
    this.handleSelectAllTickets = this.handleSelectAllTickets.bind(this);
    this.bulkApprove = this.bulkApprove.bind(this);
    this.bulkCancel = this.bulkCancel.bind(this);
    this.bulkDelete = this.bulkDelete.bind(this);
    this.handleBulkActionChange = this.handleBulkActionChange.bind(this);
    this.handleBulkActionGo = this.handleBulkActionGo.bind(this);
    this.handleSuggestionClick = this.handleSuggestionClick.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleMonthChange = this.handleMonthChange.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.filterTicketsByDateRange = this.filterTicketsByDateRange.bind(this);
    this.toggleMessageExpansion = this.toggleMessageExpansion.bind(this);
  }

  componentDidMount() {
    if (!this.props.authToken) {
      // this.props.history.push("/login");
    }
    this.ticketsData()
    const fromNotificationPanel = sessionStorage.getItem('fromNotificationPanel');
    if (this.props.location.state && this.props.location.state.notificationData && fromNotificationPanel) {
      const { dataId } = this.props.location.state.notificationData;
      this.fetchTicketDetails(dataId);
      this.setState({ showModalFromNotification: true });
      sessionStorage.removeItem('fromNotificationPanel');
    }

    const fromDashboard = sessionStorage.getItem('fromDashboard');
    if (this.props.location.state && this.props.location.state.leaveDetails && fromDashboard) {
      const { leaveDetails } = this.props.location.state;
      this.setState({ selectedLeaveDetails: leaveDetails, showApprovedDetailsPopup: true });
      sessionStorage.removeItem('fromDashboard');
    }
  }

  ticketsData = async (props) => {
    let tickets;
    const isAdmin = ls("roles") === "Super Admin";
    const isEmployeeRole = ls("roles") === "Employee";
    if (isAdmin) {
      tickets = await AuthApi.getTickets1();
    } else if (isEmployeeRole) {
      tickets = await AuthApi.getEmpLeaveticket();
    } else {
      tickets = await AuthApi.getEmpLeaveticket();
    }
    if (tickets && tickets.status === true) {
      const newarr = [];
      const ticketsData = tickets.data;
      ticketsData.forEach((element, key) => {
        const tempData = {
          id: element.id,
          user_id: element.user_id,
          firstName: element.firstName,
          leaveType: element.leaveType,
          date_range: element.date_range,
          no_of_days: element.no_of_days,
          ticket_mesasge: element.ticket_mesasge,
          status: element.status,
          cancel_reason: element.cancel_reason,
          c_reason: element.c_reason,
          created_at: element.created_at,
          updated_at: element.updated_at,
          firstleavetype: element.firstleavetype,
          secondleavetype: element.secondleavetype,
        };
        newarr.push(tempData);
      });
      const currentDate = moment();
      const sortedTickets = newarr.sort((a, b) => {
        const dateA = moment(a.date_range, "DD-MM-YYYY");
        const dateB = moment(b.date_range, "DD-MM-YYYY");
        return Math.abs(dateA.diff(currentDate)) - Math.abs(dateB.diff(currentDate));
      });
      this.setState({
        tickets: sortedTickets,
        count: sortedTickets.length,
      });
    }
  };

  fetchTicketDetails = async (dataId) => {
    try {
      const tickets = await AuthApi.getTickets1();
      if (tickets && tickets.status === true) {
        const ticket = tickets.data.find((ticket) => ticket.id === dataId);
        if (ticket) {
          this.setState({
            selectedLeaveDetails: ticket,
            showApprovedDetailsPopup: true,
          });
        }
      }
    } catch (error) {
      console.error("Error fetching ticket details:", error);
    }
  };

  updateLeaveStatus(ticketId, newStatus, reason = "", isBulk = false) {
    if (newStatus === "Cancelled" && !reason) {
      swal({
        title: "Enter Reason",
        text: "Please enter the reason for cancelling the leave:",
        content: {
          element: "input",
          attributes: {
            placeholder: "Type your reason here",
            type: "text",
          },
        },
        buttons: {
          cancel: true,
          confirm: {
            text: "OK",
            closeModal: false,
          },
        },
      }).then((inputReason) => {
        if (!inputReason) {
          swal("Cancelled", "You need to write a reason!", "error");
          return;
        }
        this.updateLeaveStatus(ticketId, newStatus, inputReason, isBulk);
      });
      return;
    }

    if (newStatus === "Declined" && !reason) {
      swal({
        title: "Enter Reason",
        text: "Please enter the reason for declining the leave:",
        content: {
          element: "input",
          attributes: {
            placeholder: "Type your reason here",
            type: "text",
          },
        },
        buttons: {
          cancel: true,
          confirm: {
            text: "OK",
            closeModal: false,
          },
        },
      }).then((inputReason) => {
        if (!inputReason) {
          swal("Cancelled", "You need to write a reason!", "error");
          return;
        }
        this.updateLeaveStatus(ticketId, newStatus, inputReason, isBulk);
      });
      return;
    }

    if (!isBulk) {
      swal({
        title: `Confirm ${newStatus}`,
        text: `Are you sure you want to ${newStatus.toLowerCase()} this ticket?`,
        buttons: true,
        dangerMode: true,
      }).then((confirm) => {
        if (confirm) {
          const loadingNode = document.createElement('div');
          ReactDOM.render(<CircularProgress />, loadingNode);
          swal({
            title: "Updating...",
            text: "Please wait while we update the ticket status.",
            buttons: false,
            closeOnClickOutside: false,
            closeOnEsc: false,
            content: loadingNode,
          });
          this.updateLeave(ticketId, newStatus, reason);
        }
      });
    } else {
      const loadingNode = document.createElement('div');
      ReactDOM.render(<CircularProgress />, loadingNode);
      swal({
        title: "Updating...",
        text: "Please wait while we update the ticket status.",
        buttons: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
        content: loadingNode,
      });
      this.updateLeave(ticketId, newStatus, reason);
    }
  }

  updateLeave(ticketId, newStatus, reason) {
    const user = ls("user");
    const userName = `${user.firstName} ${user.lastName}`;
    const ticketUpdateData = {
      leaveStatus: newStatus,
      c_reason: newStatus === "Cancelled" ? reason : "",
      cancel_reason: newStatus !== "Cancelled" ? reason : "",
      updated_by: userName,
    };

    AuthApi.updateTicketLeaveStatus(ticketUpdateData, ticketId)
      .then((response) => {
        if (response && response.status === true) {
          swal("Success", "Ticket status updated successfully", "success");
        } else {
          swal("Error", "Failed to update ticket status", "error");
        }
      })
      .catch((error) => {
        console.error("Error updating ticket status:", error);
        swal("Error", "Failed to update ticket status", "error");
      })
      .finally(() => {
        this.setState({ loading: false });
        swal.close();
        this.ticketsData();
      });

    const isApproved = newStatus === "Approved";
    const rejected = newStatus === "Declined";
    this.setState((prevState) => ({
      rejectedTickets: {
        ...prevState.rejectedTickets,
        [ticketId]: rejected ? reason : "",
      },
      approvedTickets: {
        ...prevState.approvedTickets,
        [ticketId]: isApproved,
      },
    }));
  }

  handleChange(e) {
    const searchQuery = e.target.value;
    const suggestions = this.state.tickets.filter((item) => {
      return (
        item.leaveType.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.date_range.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.no_of_days.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.ticket_mesasge.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.status.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.created_at.toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
    this.setState({ searchQuery, suggestions });
  }

  handleCloseModal = () => {
    this.setState({ showApprovedDetailsPopup: false, showModalFromNotification: false });
  };

  handleSearch = () => {
    const filteredLeave = this.state.tickets.filter((item) => {
      return (
        item.leaveType
          .toLowerCase()
          .includes(this.state.searchQuery.toLowerCase()) ||
        item.date_range
          .toLowerCase()
          .includes(this.state.searchQuery.toLowerCase()) ||
        item.no_of_days
          .toLowerCase()
          .includes(this.state.searchQuery.toLowerCase()) ||
        item.firstName
          .toLowerCase()
          .includes(this.state.searchQuery.toLowerCase()) ||
        item.ticket_mesasge
          .toLowerCase()
          .includes(this.state.searchQuery.toLowerCase()) ||
        item.status
          .toLowerCase()
          .includes(this.state.searchQuery.toLowerCase()) ||
        item.created_at
          .toLowerCase()
          .includes(this.state.searchQuery.toLowerCase())
      );
    });
    this.setState({ filteredLeave });
  };

  clearSearch = () => {
    this.setState({ searchQuery: "", suggestions: [] }, this.handleSearch);
  };

  handleSuggestionClick = (suggestion) => {
    this.setState({ searchQuery: suggestion.firstName, suggestions: [] }, this.handleSearch);
  };

  handleSelectAllTickets = () => {
    this.setState((prevState) => {
      const selectableTickets = prevState.tickets.filter(ticket => 
        !["Declined", "Cancelled", "Unapproved"].includes(ticket.status)
      ).map(ticket => ticket.id);

      const allSelected = prevState.selectedTickets.length !== selectableTickets.length;
      const selectedTickets = allSelected ? selectableTickets : [];
      return { selectedTickets, allSelected };
    });
  };

  handleSelectTicket(ticketId) {
    this.setState((prevState) => {
      const selectedTickets = prevState.selectedTickets.includes(ticketId)
        ? prevState.selectedTickets.filter((id) => id !== ticketId)
        : [...prevState.selectedTickets, ticketId];
      const allSelected = selectedTickets.length === prevState.tickets.length;
      return { selectedTickets, allSelected };
    });
  }

  isAllSelected = () => {
    const { tickets = [], selectedTickets = [] } = this.state || {};
    return tickets.length > 0 && tickets.every(ticket => selectedTickets.includes(ticket.id));
  };

  handleBulkActionChange(event) {
    this.setState({ selectedBulkAction: event.target.value });
  }

  handleBulkActionGo() {
    const { selectedBulkAction, selectedTickets, tickets } = this.state;
    const validTickets = selectedTickets.filter(ticketId => {
      const ticket = tickets.find(t => t.id === ticketId);
      return ticket && !["Declined", "Cancelled", "Unapproved"].includes(ticket.status);
    });

    if (selectedBulkAction === "approve") {
      this.bulkApprove(validTickets);
    } else if (selectedBulkAction === "decline") {
      this.bulkDelete(validTickets);
    } else if (selectedBulkAction === "cancel") {
      this.bulkCancel(validTickets);
    }
  }

  bulkApprove(validTickets) {
    swal({
      title: "Confirm Bulk Approve",
      text: "Are you sure you want to approve the selected tickets?",
      buttons: true,
      dangerMode: true,
    }).then((confirm) => {
      if (confirm) {
        validTickets.forEach((ticketId) => {
          this.updateLeaveStatus(ticketId, "Approved", "", true);
        });
      }
    });
  }

  bulkCancel(validTickets) {
    swal({
      title: "Enter Reason",
      text: "Please enter the reason for cancelling the leave:",
      content: {
        element: "input",
        attributes: {
          placeholder: "Type your reason here",
          type: "text",
        },
      },
      buttons: {
        cancel: true,
        confirm: {
          text: "OK",
          closeModal: false,
        },
      },
    }).then((inputReason) => {
      if (!inputReason) {
        swal("Cancelled", "You need to write a reason!", "error");
        return;
      }
      swal({
        title: "Confirm Bulk Cancel",
        text: "Are you sure you want to cancel the selected tickets?",
        buttons: true,
        dangerMode: true,
      }).then((confirm) => {
        if (confirm) {
          validTickets.forEach((ticketId) => {
            this.updateLeaveStatus(ticketId, "Cancelled", inputReason, true);
          });
        }
      });
    });
  }

  bulkDelete(validTickets) {
    swal({
      title: "Enter Reason",
      text: "Please enter the reason for declining the leave:",
      content: {
        element: "input",
        attributes: {
          placeholder: "Type your reason here",
          type: "text",
        },
      },
      buttons: {
        cancel: true,
        confirm: {
          text: "OK",
          closeModal: false,
        },
      },
    }).then((inputReason) => {
      if (!inputReason) {
        swal("Cancelled", "You need to write a reason!", "error");
        return;
      }
      swal({
        title: "Confirm Bulk Decline",
        text: "Are you sure you want to decline the selected tickets?",
        buttons: true,
        dangerMode: true,
      }).then((confirm) => {
        if (confirm) {
          validTickets.forEach((ticketId) => {
            this.updateLeaveStatus(ticketId, "Declined", inputReason, true);
          });
        }
      });
    });
  }

  handleFilterChange(event) {
    const value = event.target.value;
    console.log("Selected value:", value); 
  
    if (value[0] === "All" && value.length > 1) {
     
      const filteredValues = value.slice(1);
      this.setState({ filterStatus: filteredValues }, () => {
        // console.log("Filter status after update:", this.state.filterStatus); 
      });
    } else if (value.includes("All")) {
      this.setState({ filterStatus: ["All"] }, () => {
        // console.log("Filter status after update:", this.state.filterStatus); 
      });
    } else {
      this.setState({ filterStatus: value }, () => {
        // console.log("Filter status after update:", this.state.filterStatus); 
      });
    }
  }

  handleMonthChange(event) {
    this.setState({ selectedMonth: event.target.value }, this.filterTicketsByMonth);
  }

  filterTicketsByMonth() {
    const { selectedMonth, tickets } = this.state;
    if (!selectedMonth) {
      this.setState({ filteredLeave: tickets });
      return;
    }
    const filteredTickets = tickets.filter(ticket => {
      const [startDate] = ticket.date_range.split(' to ');
      const ticketMonth = moment(startDate, "DD-MM-YYYY").format('YYYY-MM');
      return ticketMonth === selectedMonth;
    });
    this.setState({ filteredLeave: filteredTickets });
  }

  handleStartDateChange(date) {
    this.setState({ startDate: date }, this.filterTicketsByDateRange);
  }

  handleEndDateChange(date) {
    this.setState({ endDate: date }, this.filterTicketsByDateRange);
  }

  filterTicketsByDateRange() {
    const { startDate, endDate, tickets } = this.state;
    if (!startDate || !endDate) {
      this.setState({ filteredLeave: tickets });
      return;
    }
    const filteredTickets = tickets.filter(ticket => {
      const [startRange] = ticket.date_range.split(' to ');
      const ticketDate = moment(startRange, "DD-MM-YYYY");
      return ticketDate.isBetween(startDate, endDate, null, '[]');
    });
    this.setState({ filteredLeave: filteredTickets });
  }

  toggleMessageExpansion(ticketId) {
    this.setState((prevState) => ({
      expandedMessages: {
        ...prevState.expandedMessages,
        [ticketId]: !prevState.expandedMessages[ticketId],
      },
    }));
  }

  render() {
    const classes = this.props;
    const filteredLeave = this.state.filteredLeave.length > 0 || this.state.selectedMonth ? this.state.filteredLeave : this.state.tickets;
    const isAdmin = ls("roles") === "Super Admin";
    const permissions = (this.props.authUser &&
      Array.isArray(this.props.authUser.userAccess))
      ? this.props.authUser.userAccess
      : [];
    const allStatuses = ["approved", "declined", "pending", "cancelled", "unapproved"];
    const filteredAndSortedLeave = filteredLeave
      .filter(item => {
        if (this.state.filterStatus.includes("All")) return true;
        return this.state.filterStatus.includes(item.status.toLowerCase());
      })
      .sort((a, b) => {
        const selectedStatuses = this.state.filterStatus.map(status => status.toLowerCase());
        if (this.state.filterStatus.includes("All")) {
          if (a.status.toLowerCase() === "pending" && b.status.toLowerCase() !== "pending") {
            return -1;
          }
          if (a.status.toLowerCase() !== "pending" && b.status.toLowerCase() === "pending") {
            return 1;
          }
        }
        const statusOrderA = selectedStatuses.indexOf(a.status.toLowerCase());
        const statusOrderB = selectedStatuses.indexOf(b.status.toLowerCase());
        if (statusOrderA !== statusOrderB) {
          return statusOrderA - statusOrderB;
        }
        return moment(b.created_at, "DD-MM-YYYY hh:mm A").diff(moment(a.created_at, "DD-MM-YYYY hh:mm A"));
      });
    const userId = ls("user").id;

    return (
      <div>
        <AuthCheckComponent authToken={this.props.authToken} />
        <Header
          {...this.props}
          authUser={this.props.authUser}
          setAutUser={this.props.setAutUser}
          component={
            <div className="Ticketmodule" style={{ marginBottom: "11%" }}>
              <Card className={classes.root} style={{ marginBottom: "3%" }}>
                <CardContent>
                  <Breadcrumb
                    {...this.props}
                    primaryPageName="Leaves Ticket"
                    primaryPageLink="/tickets"
                    isSecondaryPage={false}
                    secondaryPageName=""
                  />
                </CardContent>
              </Card>

              <Grid
                style={{
                  marginBottom: "20px",
                  minWidth: "165px",
                  right: "875px",
                  position: "absolute",
                  marginTop: "8px"
                }}>
                <TextField
                  id="datetime-local"
                  label="Select Month/Year"
                  variant="outlined"
                  type="month"
                  value={this.state.selectedMonth}
                  onChange={(e) => this.handleMonthChange(e)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{ min: "2000-01", max: new Date().toISOString().slice(0, 7) }}
                  fullWidth
                />
              </Grid>

              <Grid style={{ Right: "20px", minWidth: "150px", position: "absolute", right: "567px", marginTop: "12px" }}>
                <TextField
                  type="date"
                  label="Start Date"
                  value={this.state.startDate ? moment(this.state.startDate).format('YYYY-MM-DD') : ''}
                  onChange={(e) => this.handleStartDateChange(moment(e.target.value, 'YYYY-MM-DD'))}
                  style={{ marginRight: "20px" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  type="date"
                  label="End Date"
                  value={this.state.endDate ? moment(this.state.endDate).format('YYYY-MM-DD') : ''}
                  onChange={(e) => this.handleEndDateChange(moment(e.target.value, 'YYYY-MM-DD'))}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <FormControl style={{ Right: "20px", minWidth: "150px", position: "absolute", right: "405px", marginTop: "27px" }}>
                <InputLabel id="filter-status-label" style={{ top: "-10px", marginLeft: "6px", fontWeight: 600 }}>Filter Leave Status</InputLabel>
                <Select
                  labelId="filter-status-label"
                  multiple
                  value={this.state.filterStatus}
                  onChange={this.handleFilterChange}
                  renderValue={(selected) => selected.join(', ')}
                  style={{ width: "134px" }}
                >
                  <MenuItem value="All" >
                    <Checkbox checked={this.state.filterStatus.includes("All")} />
                    All
                  </MenuItem>
                  {allStatuses.map(status => (
                    <MenuItem key={status} value={status}>
                      <Checkbox checked={this.state.filterStatus.includes(status)} />
                      {status.charAt(0).toUpperCase() + status.slice(1)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                label="Search"
                value={this.state.searchQuery}
                onChange={this.handleChange}
                style={{
                  position: "absolute",
                  right: "121px",
                  marginTop: "20px",
                }}
                variant="outlined"
                InputLabelProps={{
                  shrink: this.state.searchQuery !== "",
                  style: {
                    color: "#1b6e95",
                    transform:
                      this.state.searchQuery !== ""
                        ? "translate(14px, -6px) scale(0.75)"
                        : "translate(14px, 11px) scale(1)",
                    transition: "transform 0.2s ease-in-out",
                  },
                }}
                InputProps={{
                  style: {
                    backgroundColor: "lightgray",
                    borderRadius: "4px",
                    height: "40px",
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      {this.state.searchQuery && (
                        <ClearIcon
                          onClick={this.clearSearch}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                      <SearchIcon
                        onClick={this.handleSearch}
                        style={{ cursor: "pointer" }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
              {this.state.searchQuery && this.state.suggestions.length > 0 && (
                <Paper
                  style={{
                    position: 'absolute',
                    right: '188px',
                    marginTop: '62px',
                    zIndex: 1,
                    width: "209px",
                    maxHeight: '200px',
                    overflowY: 'auto',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)'
                  }}>
                  {[...new Set(this.state.suggestions.map(suggestion => suggestion.firstName))].map((name, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => this.handleSuggestionClick({ firstName: name })}
                      style={{
                        padding: '10px 20px',
                        cursor: 'pointer'
                      }}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </Paper>
              )}

              <TableComponent
                {...this.props}
                tableTh={this.state.tableTh.map((column) => {
                  if (column.id === "select") {
                    const selectableTickets = this.state.tickets.filter(ticket => 
                      !["Declined", "Cancelled", "Unapproved"].includes(ticket.status)
                    );

                    return {
                      ...column,
                      label: selectableTickets.length > 0 ? (
                        <div className="wrap-check-20">
                          <label>
                            <input
                              type="checkbox"
                              checked={this.state.allSelected}
                              onChange={this.handleSelectAllTickets}
                            />
                            <span className="checkbox"></span>
                          </label>
                        </div>
                      ) : null,
                    };
                  }
                  return column;
                })}
                style={{ tableLayout: "fixed" }}
                bodyStyle={{ overflowX: "auto" }}
                wrapperStyle={{ minWidth: "100%" }}
                headerStyle={{ background: "#f2f2f2" }}
                tableData={filteredAndSortedLeave.map((item) => ({
                  ...item,
                  select: (
                    <div className="wrap-check-20">
                      {["Declined", "Cancelled", "Unapproved"].includes(item.status) ? null : (
                        <label>
                          <input
                            type="checkbox"
                            checked={this.state.selectedTickets.includes(item.id)}
                            onChange={() => this.handleSelectTicket(item.id)}
                          />
                          <span className="checkbox"></span>
                        </label>
                      )}
                    </div>
                  ),
                  actions: (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "row",
                        }}
                      >
                        {item.status === "Pending" && item.user_id !== userId ? (
                          <>
                            {permissions.includes("Leaveticket.Approve") && (
                              <Tooltip title="Approve">
                                <IconButton
                                  onClick={() =>
                                    this.updateLeaveStatus(item.id, "Approved")
                                  }
                                  style={{ cursor: "pointer", padding: "2px" }}
                                  variant="contained"
                                  color="success"
                                >
                                  <FontAwesomeIconComponent
                                    classes="far fa-check-square"
                                    colorName="success"
                                    fontSize={"small"}
                                  />
                                </IconButton>
                              </Tooltip>
                            )}

                            {permissions.includes("Leaveticket.Declined") && (
                              <Tooltip title="Declined">
                                <IconButton
                                  onClick={() =>
                                    this.updateLeaveStatus(item.id, "Declined")
                                  }
                                  variant="contained"
                                  color="red"
                                  style={{ padding: "2px" }}
                                >
                                  <FontAwesomeIconComponent
                                    classes="fas fa-window-close"
                                    colorName="primary"
                                    fontSize={"small"}
                                    style={{
                                      cursor: "pointer",
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            )}

                            {permissions.includes("Leaveticket.Unapproved") && (
                              <Tooltip title="Unapproved">
                                <IconButton
                                  onClick={() =>
                                    this.updateLeaveStatus(item.id, "Unapproved")
                                  }
                                  variant="contained"
                                  color="red"
                                  style={{ padding: "2px", color: "red" }}
                                >
                                  <FontAwesomeIconComponent
                                    classes="fas fa-times-circle"
                                    // colorName="primary"
                                    fontSize={"small"}
                                    style={{
                                      cursor: "pointer",
                                      color: "#ff0000"
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            )}
                          </>
                        ) : null}
                      </div>

                      {/* {item.status === "Pending" &&
                        permissions.includes("Leaveticket.Edit") &&  item.user_id === userId && ( // Added condition to check userId
                          <Tooltip title="Update">
                            <IconButton
                              onClick={() => {
                                this.props.history.push(
                                  `/tickets/edit/${item.id}`
                                );
                              }}
                              variant="contained"
                              color="primary"
                              style={{ padding: "2px" }}
                            >
                              <FontAwesomeIconComponent
                                classes="fa fa-edit"
                                style={{ cursor: "pointer" }}
                                fontSize={"small"}
                              />
                            </IconButton>
                          </Tooltip>
                        )} */}


                      {item.status === "Pending" && item.user_id === userId &&
                        permissions.includes("Leaveticket.Edit") && (
                          <Tooltip title="Update">
                            <IconButton
                              onClick={() => {
                                this.props.history.push(
                                  `/tickets/edit/${item.id}`
                                );
                              }}
                              variant="contained"
                              color="primary"
                              style={{ padding: "2px" }}
                            >
                              <FontAwesomeIconComponent
                                classes="fa fa-edit"
                                style={{ cursor: "pointer" }}
                                fontSize={"small"}
                              />
                            </IconButton>
                          </Tooltip>
                        )}

                      {permissions.includes("Leaveticket.Cancel") && (
                        <Tooltip title="Cancel Leave Request">
                          <IconButton
                            onClick={() => {
                              this.updateLeaveStatus(item.id, "Cancelled");
                            }}
                            variant="contained"
                            color="red"
                            style={{
                              padding: "2px",
                              display:
                                item.status !== "Cancelled" ? "block" : "none",
                            }}
                          >
                            <FontAwesomeIconComponent
                              classes="fa fa-ban"
                              style={{
                                cursor: "pointer",
                              }}
                              fontSize={"small"}
                            />
                          </IconButton>
                        </Tooltip>
                      )}

                      {permissions.includes("Leaveticket.Viewdetails") && (
                        <Tooltip
                          title="View Details"
                          className="custom-tooltip"
                        >
                          <IconButton
                            onClick={() => {
                              if (
                                item.status === "Declined" &&
                                ls("roles") === "Employee"
                              ) {
                                this.setState({
                                  rejectedReason: this.state.rejectedTickets[item.id],
                                  showRejectedReason: true,
                                  showApprovedDetailsPopup: true,
                                });
                              } else {
                                this.setState({
                                  selectedLeaveDetails: item,
                                  showApprovedDetailsPopup: true,
                                });
                              }
                            }}
                            variant="contained"
                            color="primary"
                            style={{ cursor: "pointer", padding: "2px" }}
                          >
                            <FontAwesomeIconComponent
                              classes="fa fa-info-circle"
                              colorName="success"
                              fontSize={"small"}
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                    </div>
                  ),
                  status: this.state.approvedTickets[item.id]
                    ? "Approved"
                    : item.status,
                  ticket_mesasge: (
                    <div>
                      {this.state.expandedMessages[item.id] || item.ticket_mesasge.length <= 50
                        ? item.ticket_mesasge
                        : `${item.ticket_mesasge.substring(0, 50)}...`}
                      {item.ticket_mesasge.length > 50 && (
                        <span
                          onClick={() => this.toggleMessageExpansion(item.id)}
                          style={{ color: '#1b6e95', cursor: 'pointer', marginLeft: '5px', display: 'inline-block' }}
                        >
                          {this.state.expandedMessages[item.id] ? 'Read Less' : 'Read More'}
                        </span>
                      )}
                    </div>
                  ),
                }))}
                rowStyle={(item) => ({
                  backgroundColor:
                    item.status === "Approved"
                      ? "#c7f1c7"
                      : item.status === "Declined"
                        ? "#f1b5b5"
                        : item.status === "Cancelled"
                          ? "#EDB271"
                          : item.status === "Unapproved"
                            ? "#ff3628"
                            : "#dddd8b",
                })}
                tableCount={filteredAndSortedLeave.length}
                colNameToShow={[
                  "select",
                  "firstName",
                  "leaveType",
                  "date_range",
                  "no_of_days",
                  "ticket_mesasge",
                  "status",
                  "cancel_reason",
                  "c_reason",
                  "created_at",
                  "updated_at",
                  "actions",
                ]}
                openPopUp={false}
                removeRow={this.removeLeaves}
                // actionBtns={ls("roles") === "Employee"
                //   ? filteredAndSortedLeave.some((item) => item.status === "Pending")
                //     ? [permissions.includes("Leaveticket.Declined") && "update1"]
                //     : []
                //   : []
                // }
                actionBtns={ls("roles") === "Employee"
                  ? filteredAndSortedLeave.some((item) => item.status === "Pending" && item.user_id === userId)
                    ? [permissions.includes("Leaveticket.Declined") && ""]
                    : []
                  : []
                }
                modelName={"Leaves Tickets"}
                addRoute={"/tickets/add"}
                updateRoute={"/tickets/edit"}
                openPopUpUpdate={false}
                noDataMessage="No data found"
              />

              {(permissions.includes("Leaveticket.Bulkapprove") || permissions.includes("Leaveticket.Bulkdecline") || permissions.includes("Leaveticket.Bulkcancel")) && (
                <div style={{ marginTop: "-53px", paddingBottom: "64px", position: "absolute" }}>
                  <Select
                    value={this.state.selectedBulkAction}
                    onChange={this.handleBulkActionChange}
                    style={{ marginRight: "10px", minWidth: "150px" }}
                    displayEmpty
                  >
                    <MenuItem value="">
                      <em>Select Bulk Action</em>
                    </MenuItem>
                    {permissions.includes("Leaveticket.Bulkapprove") && (
                      <MenuItem value="approve">Bulk Approve</MenuItem>
                    )}
                    {permissions.includes("Leaveticket.Bulkdecline") && (
                      <MenuItem value="decline">Bulk Decline</MenuItem>
                    )}
                    {permissions.includes("Leaveticket.Bulkcancel") && (
                      <MenuItem value="cancel">Bulk Cancel</MenuItem>
                    )}
                    {/* {permissions.includes("Leaveticket.Unapproved") && (
                      <MenuItem value="Unapproved">Bulk Unapproved</MenuItem>
                    )} */}
                  </Select>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleBulkActionGo}
                    disabled={this.state.selectedTickets.length === 0 || !this.state.selectedBulkAction}
                  >
                    Go
                  </Button>
                </div>
              )}

              {this.state.showApprovedDetailsPopup && (
                <Dialog
                  open={this.state.showApprovedDetailsPopup}
                  onClose={() => this.setState({ showApprovedDetailsPopup: false })}
                  maxWidth="md"
                  fullWidth
                  PaperProps={{
                    style: {
                      borderRadius: "8px",
                      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
                      width: "600px",
                      height: "360px"
                    },
                  }}
                >
                  <div style={{ position: "relative" }}>
                    <DialogTitle
                      style={{
                        textAlign: "center",
                        padding: "5px 0",
                        backgroundColor: "#1b6e95",
                        color: "#fff",
                        borderTopLeftRadius: "8px",
                        borderTopRightRadius: "8px",
                      }}
                    >
                      Leave Details
                      <CloseIcon
                        style={{
                          position: "absolute",
                          top: 10,
                          right: 10,
                          cursor: "pointer",
                          color: "#fff",
                          backgroundColor: "#f44336",
                          borderRadius: "50%",
                          padding: "2px",
                        }}
                        onClick={() => this.setState({ showApprovedDetailsPopup: false })}
                      />
                    </DialogTitle>
                    <DialogContent>
                      {this.state.selectedLeaveDetails && (
                        <>
                          {console.log(this.state.selectedLeaveDetails)}
                          <div>
                            <table style={{ width: "100%", borderCollapse: "collapse" }}>
                              <tbody>
                                <tr>
                                  <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}><b>Name</b></td>
                                  <td style={{ padding: "8px", borderBottom: "1px solid #ddd", textAlign: "center" }}>:</td>
                                  <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>{this.state.selectedLeaveDetails.firstName}</td>
                                </tr>
                                <tr>
                                  <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}><b>Leave Type</b></td>
                                  <td style={{ padding: "8px", borderBottom: "1px solid #ddd", textAlign: "center" }}>:</td>
                                  <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>{this.state.selectedLeaveDetails.leaveType}</td>
                                </tr>
                                <tr>
                                  <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}><b>Created On</b></td>
                                  <td style={{ padding: "8px", borderBottom: "1px solid #ddd", textAlign: "center" }}>:</td>
                                  <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>{this.state.selectedLeaveDetails.created_at}</td>
                                </tr>
                                <tr>
                                  <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}><b>Reason for Leave</b></td>
                                  <td style={{ padding: "8px", borderBottom: "1px solid #ddd", textAlign: "center" }}>:</td>
                                  <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>
                                    {this.state.selectedLeaveDetails.ticket_mesasge ? (
                                      <>
                                        {this.state.isReasonExpanded || this.state.selectedLeaveDetails.ticket_mesasge.length <= 50
                                          ? this.state.selectedLeaveDetails.ticket_mesasge
                                          : `${this.state.selectedLeaveDetails.ticket_mesasge.substring(0, 50)}...`}
                                        {this.state.selectedLeaveDetails.ticket_mesasge.length > 50 && (
                                          <span
                                            onClick={() => this.setState({ isReasonExpanded: !this.state.isReasonExpanded })}
                                            style={{ color: '#1b6e95', cursor: 'pointer', marginLeft: '5px', display: 'inline-block' }}
                                          >
                                            {this.state.isReasonExpanded ? 'Read Less' : 'Read More'}
                                          </span>
                                        )}
                                      </>
                                    ) : 'N/A'}
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}><b>Date Range</b></td>
                                  <td style={{ padding: "8px", borderBottom: "1px solid #ddd", textAlign: "center" }}>:</td>
                                  <td style={{ padding: "8px", borderBottom: "1px solid #ddd", whiteSpace: "nowrap" }}>
                                    {(() => {
                                      const dateRange = this.state.selectedLeaveDetails.date_range;
                                      let startDate, endDate;

                                      if (dateRange.includes(' to ')) {
                                        [startDate, endDate] = dateRange.split(' to ');
                                      } else {
                                        [startDate, endDate] = dateRange.split(' To ');
                                      }

                                      const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
                                      return `${startDate} (${capitalize(this.state.selectedLeaveDetails.firstleavetype)}) To ${endDate} (${capitalize(this.state.selectedLeaveDetails.secondleavetype)})`;
                                    })()}
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}><b>No Of Days</b></td>
                                  <td style={{ padding: "8px", borderBottom: "1px solid #ddd", textAlign: "center" }}>:</td>
                                  <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>{this.state.selectedLeaveDetails.no_of_days}</td>
                                </tr>
                                <tr>
                                  <td style={{ padding: "8px", }}><b>Status</b></td>
                                  <td style={{ padding: "8px",  textAlign: "center" }}>:</td>
                                  <td style={{ padding: "8px", }}>{this.state.selectedLeaveDetails.status}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          {isAdmin && this.state.selectedLeaveDetails.status === "Pending" && (
                            <div
                              style={{
                                position: "absolute",
                                right: 0,
                                cursor: "pointer",
                                margin: "5px"
                              }}
                            >
                              <Tooltip title="Approve">
                                <Button
                                  onClick={() => {
                                    this.updateLeaveStatus(
                                      this.state.selectedLeaveDetails.id,
                                      "Approved"
                                    );
                                    this.setState({ showApprovedDetailsPopup: false });
                                  }}
                                  variant="contained"
                                  color="primary"
                                  style={{ margin: "5px", backgroundColor: "  #1B6E95" }}
                                >
                                  Approve
                                </Button>
                              </Tooltip>
                              <Tooltip title="Declined">
                                <Button
                                  onClick={() => {
                                    this.updateLeaveStatus(
                                      this.state.selectedLeaveDetails.id,
                                      "Declined"
                                    );
                                    this.setState({ showApprovedDetailsPopup: false });
                                  }}
                                  variant="contained"
                                  color="primary"
                                  style={{ margin: "5px", backgroundColor: "#db3535" }}
                                >
                                  Declined
                                </Button>
                              </Tooltip>
                            </div>
                          )}
                        </>
                      )}
                    </DialogContent>
                  </div>
                </Dialog>
              )}

              {this.state.loading && (
                <div className="loading-spinner">
                  <CircularProgress />
                </div>
              )}
            </div>
          }
        />
        <Footer {...this.props} />
      </div>
    );
  }
}

export default withRouter(Ticket);
