import React, { useState, useEffect } from 'react';
import Header from '../../Layouts/Header';
import Footer from '../../Layouts/Footer';
import Breadcrumb from '../../Layouts/Breadcrumb';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import swal from "sweetalert";
import AuthApi from "../../Services/Authapi";
import TextField from '@material-ui/core/TextField';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import draftToHtml from 'draftjs-to-html';
import AuthCheckComponent from '../../Layouts/Token';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import EmailEditor from 'react-email-editor';

const iniLatter = {
    template_name: '',
    template_subject: '',
    texteditor: '',
    template_design: '',
    templates: []
    // templates: [{ label: '', type: '' }]
}

const Latter = (props) => {
    const classes = props;
    const [doc, setDoc] = useState(iniLatter);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDocUpdated, setIsDocUpdated] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    // const [templateData, setTemplateData] = useState([]); 

    useEffect(() => {
        if (props && props.authToken === false) {
            // props.history.push('/login');
        }
    }, [props]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setDoc({ ...doc, [name]: value });
    };

    const handleEditorChange = (data) => {
        setDoc({ ...doc, texteditor: data });
    };

    const exportHtml = () => {
        return new Promise((resolve, reject) => {
            emailEditorRef.current.editor.exportHtml((data) => {
                const { design, html } = data;
                const updatedDesign = JSON.stringify(design, (key, value) => {
                    if (key === 'selectable' || key === 'draggable' || key === 'duplicatable' || key === 'deletable' || key === 'hideable') {
                        return true;
                    }
                    return value;
                });
                const updatedDoc = { ...doc, texteditor: html, template_design: updatedDesign };
                
                // Update the state and set the flag
                setDoc(updatedDoc);
                setIsDocUpdated(true);
                resolve(updatedDoc); 
            });
        });
    };

    const emailEditorRef = React.useRef(null);

    const fontConfig = {
        fonts: {
            showDefaultFonts: true,
            customFonts: [
                { label: 'Arial', value: 'Arial, sans-serif' },
                { label: 'Georgia', value: 'Georgia, serif' },
                { label: 'Times New Roman', value: 'Times New Roman, serif' },
                { label: 'Verdana', value: 'Verdana, sans-serif' },
                { label: 'Courier New', value: '"Courier New", monospace' },
                { label: 'Tahoma', value: 'Tahoma, sans-serif' },
                { label: 'Trebuchet MS', value: '"Trebuchet MS", sans-serif' },
                { label: 'Lucida Sans', value: '"Lucida Sans", sans-serif' },
                { label: 'Roboto', value: 'Roboto, sans-serif' },
                { label: 'Open Sans', value: '"Open Sans", sans-serif' },
                { label: 'Lato', value: 'Lato, sans-serif' },
                { label: 'Montserrat', value: 'Montserrat, sans-serif' },
                { label: 'Oswald', value: 'Oswald, sans-serif' },
                { label: 'Raleway', value: 'Raleway, sans-serif' },
                { label: 'Merriweather', value: 'Merriweather, serif' },
                { label: 'Ubuntu', value: 'Ubuntu, sans-serif' },
                { label: 'Playfair Display', value: 'Playfair Display, serif' },
                { label: 'Poppins', value: 'Poppins, sans-serif' },
                { label: 'Quicksand', value: 'Quicksand, sans-serif' },
                { label: 'Roboto Slab', value: 'Roboto Slab, serif' },
                { label: 'Source Sans Pro', value: 'Source Sans Pro, sans-serif' },
                { label: 'Varela Round', value: 'Varela Round, sans-serif' },
                { label: 'Work Sans', value: 'Work Sans, sans-serif' },
                { label: 'Dancing Script', value: '"Dancing Script", cursive' },
                { label: 'Pacifico', value: '"Pacifico", cursive' },
                { label: 'Great Vibes', value: '"Great Vibes", cursive' },
                { label: 'Lobster', value: '"Lobster", cursive' },
                { label: 'Bebas Neue', value: '"Bebas Neue", cursive' },
                { label: 'Cinzel', value: 'Cinzel, serif' },
                { label: 'Abril Fatface', value: '"Abril Fatface", cursive' },
                { label: 'Amatic SC', value: '"Amatic SC", cursive' },
                { label: 'Josefin Sans', value: '"Josefin Sans", sans-serif' },
                { label: 'Playfair Display SC', value: '"Playfair Display SC", serif' },
                // Add more custom fonts here
            ],
        },
    };

    const validate = (values) => {
        let errors = {};
        const regex = /^[a-zA-Z]/;

        if (!values.template_name) {
            errors.template_name = "Cannot be blank";
        } else if (!regex.test(values.template_name)) {
            errors.template_name = "Invalid title format";
        }

        // values.templates.forEach((template, index) => {
        //     if (!template.label) {
        //         errors[`templateLabel${index}`] = "Template label cannot be blank";
        //     }
        //     if (!template.type) {
        //         errors[`templateType${index}`] = "Template type cannot be blank";
        //     }
        // });

        return errors;
    };

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmitting) {
       
        }
    }, [formErrors, isSubmitting]);

    // useEffect(() => {
    //     if (isDocUpdated) {
    //         createLeaves(doc);
    //         setIsDocUpdated(false); 
    //     }
    // }, [isDocUpdated, doc]);

    const handleSave = async () => {
        if (isSaving) return; // Prevent multiple submissions
        setIsSaving(true); // Set saving status to true
        swal({
            title: "Loading",
            text: "Please wait...",
            buttons: false,
            closeOnClickOutside: false,
            closeOnEsc: false,
            width: "10%",
            content: {
                element: "img",
                attributes: {
                    src: "https://www.boasnotas.com/img/loading2.gif",
                    style: "width: 50px;"
                }
            }
        });

        try {
            const updatedDoc = await exportHtml();
            setFormErrors(validate(updatedDoc));
            await createLeaves(updatedDoc); // Call createLeaves once
        } catch (error) {
            console.error("Error exporting HTML:", error);
            swal({
                title: "Error",
                text: "An error occurred while exporting the template. Please try again.",
                icon: "error",
                button: "OK",
            });
        } finally {
            setIsSaving(false); // Reset saving status
        }
    };

    const createLeaves = async (updatedDoc) => {
        try {
            if (!updatedDoc.texteditor || !updatedDoc.template_design) {
                throw new Error("Document is not properly updated.");
            }
            let create = await AuthApi.emailTemplateadd({
                ...updatedDoc,
                template_body: updatedDoc.texteditor,
                template_design: updatedDoc.template_design
            });
            swal.close();
            if (create && create.status === true) {
                swal({
                    title: "Success!",
                    text: "Template created successfully.",
                    icon: "success",
                    button: "OK",
                }).then(() => {
                    props.history.push("/emailtemplates");
                });
            } else {
                swal({
                    title: "OOPS!",
                    icon: "fail",
                    message: "Something went wrong, Please try later!",
                });
            }
        } catch (error) {
            console.error("Error updating role:", error);
            swal({
                title: "Error",
                text: "An error occurred while creating the template. Please try again.",
                icon: "error",
                button: "OK",
            });
        }
    };

    // const addTemplateField = () => {
    //     setDoc({ ...doc, templates: [...doc.templates, { label: '', type: '' }] });
    // };

    // const removeTemplateField = (index) => {
    //     const newTemplates = doc.templates.filter((_, i) => i !== index);
    //     setDoc({ ...doc, templates: newTemplates });
    // };

    return (
        <div>
            <AuthCheckComponent authToken={props.authToken} />
            <Header
                {...props}
                authUser={props.authUser}
                component={
                    <div>
                        <Card className={classes.root} style={{ marginBottom: '3%' }}>
                            <CardContent>
                                <Breadcrumb {...props} primaryPageName="Email Templates" primaryPageLink="/emailtemplates" isSecondaryPage={false} secondaryPageName="" />
                            </CardContent>
                        </Card>
                        <Grid container className={classes.root} spacing={2}>
                            <Grid item xs={12}>
                                <Card className={classes.root}>
                                    <CardContent>
                                        <Typography variant="h2" style={{ paddingTop: 0, paddingLeft: 0, paddingBottom: 0 }}>Generate Email Template</Typography>
                                        <form className={classes.form} onSubmit={handleSave}>
                                            <Grid container className={classes.root} spacing={5}>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        id="outlined-basic"
                                                        label="Template Name"
                                                        name="template_name"
                                                        variant="outlined"
                                                        error={formErrors.template_name && true}
                                                        value={doc.template_name}
                                                        onChange={handleChange}
                                                        className={formErrors.template_name && "input-error"}
                                                    />
                                                    {formErrors.template_name && (
                                                        <span className="error">{formErrors.template_name}</span>
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container className={classes.root} spacing={5}>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        id="outlined-basic"
                                                        label="Template Subject"
                                                        name="template_subject"
                                                        variant="outlined"
                                                        error={formErrors.template_subject && true}
                                                        value={doc.template_subject}
                                                        onChange={handleChange}
                                                        className={formErrors.template_subject && "input-error"}
                                                    />
                                                    {formErrors.template_subject && (
                                                        <span className="error">{formErrors.template_subject}</span>
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container className={classes.root} spacing={5}>
                                                <Grid item xs={12}>
                                                    <EmailEditor
                                                        ref={emailEditorRef}
                                                        onLoad={() => console.log('Email Editor Loaded')}
                                                        onDesignLoad={(data) => console.log('Design Loaded', data)}
                                                        options={fontConfig}
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid container className={classes.root} spacing={3}>
                                                <Grid item xs={4}>
                                                    <Button
                                                        type="button"
                                                        onClick={handleSave}
                                                        disabled={isSaving}
                                                        style={{ marginTop: "10px", float: 'left', marginBottom: "15px" }}
                                                        variant="contained"
                                                        color="primary"
                                                    >
                                                        Save
                                                    </Button>
                                                    <Button type="button" style={{ marginTop: "10px", float: 'left', marginBottom: "15px" }} onClick={(e) => { e.preventDefault(); props.history.push('/emailtemplates') }}>Cancel</Button>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </div>
                } />

            <Footer />
        </div>
    )
}

export default Latter;